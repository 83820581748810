<template>
    <Layout  style="background-color: #e9ecef;height: 1000px">
        <PageHeader :title="title" />
        <hr style="margin-top: -1rem;height: 1px;"/>
        <div style="background-color: white;min-height: 200px;max-height: 800px;overflow:auto">
            <div class="col-12">
                <div class="row mt-4">

                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" >
                            <label class="d-inline-flex align-items-center" style="margin-left: 8px">
                                <b-form-input
                                        v-model="blogSearchForm.blogTitle"
                                        type="search"
                                        placeholder="标题"
                                        class="form-control form-control-sm ml-2"
                                ></b-form-input>
<!--                                <select class="form-control" style="float: left;width:70%" v-model="form.typeId" :class="{ 'is-invalid': submitform && $v.form.typeId.$error }">-->
<!--                                    <option  v-for="type in typeList" :key="type.id" :value="type.id">{{type.typeName}}</option>-->
<!--                                </select>-->
                                <b-form-select
                                        v-model="blogSearchForm.typeId"
                                        type="search"
                                        placeholder="分类"
                                        class="form-control form-control-sm ml-2">
                                    <b-select-option v-for="type in typeList" :key="type.id" :value="type.id">{{type.typeName}}</b-select-option>
                                </b-form-select>
                                <div class="icon-demo-content">
                                    <div class="col-lg-4" style="margin-top: 0px;float: left">
                                        <i class="ri-search-line" title="搜索" @click="searchData"></i>
                                    </div>
                                    <div class="col-lg-4" style="margin-top: 0px;margin-left:15px;float: left">
                                        <i class="ri-close-circle-line" title="清空" @click="clearCondition"></i>
                                    </div>
                                </div>
                                <div class="icon-demo-content">

                                </div>
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">

                            <div class="table-responsive">
                                <table class="table table-hover mb-0">
                                    <thead>
                                    <tr olspan="24">
                                        <th width="2%">#</th>
                                        <th width="50%">标题</th>
                                        <th width="10%">分类</th>
                                        <th width="10%">标签</th>
                                        <th width="6%">阅读量</th>
                                        <th width="15%">发布时间</th>
                                        <th width="7%">操作</th>
                                    </tr>
                                    </thead>
                                    <tbody v-for="col in blogList" v-bind:key="col.id">
                                    <tr colspan="24" >
                                        <th scope="row" colspan="1">
                                            <i v-if="col.id != detailId"  class="fas fa-angle-right" @click="openDetail(col.id)"></i>
                                            <i v-if="col.id == detailId" class="fas fa-angle-down" @click="openDetail(col.id)"></i>
                                        </th>
                                        <td col="12" width="50%">
                                            <router-link :to="`/article/${col.id}`">
                                                <div class="feature-title"><a>{{col.blogTitle}}</a></div>
                                            </router-link>
                                        </td>
                                        <td col="2">{{col.typeName}}</td>
                                        <td col="2">{{col.tags}}</td>
                                        <td col="2">{{col.viewCount}}</td>
                                        <td col="2">{{col.pubTime | dataFormat4}}</td>
                                        <td col="1">
                                            <div>
                                                <i v-if="!col.blogIsTop" title="置顶" class="mdi mdi-arrow-collapse-up" style="color: #2ac06d;margin-right: 5px" @click="isTop(col,true)"></i>
                                                <i v-if="col.blogIsTop" title="取消置顶" class="mdi mdi-arrow-collapse-down" style="color: #2ac06d;margin-right: 5px" @click="isTop(col,false)"></i>
                                                <i title="编辑" class="ri-edit-line" style="color: #3a8ee6;margin-right: 5px" @click="editBlog(col)"></i>
                                                <i title="删除" class="ri-delete-bin-6-line" style="color: red" @click="removeBlog(col)"></i>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="col.id == detailId" colspan="24">
                                        <td width="2%">
                                        </td>
                                        <td colspan="10">
                                            <div>
                                                <div style="border: 2px dashed  #eeeeee;float:left;width: 50%;" align="center">
                                                    <div style="margin-top: 10px">
                                                        <h6><b>文章封面</b></h6>
                                                    </div>
                                                    <img :src="picServer+col.titlePage" alt=""
                                                         style="width: 100%;padding: 20px 20px">
                                                </div>
                                                <div style="width: 48%; border: 2px dashed #eeeeee;max-height:500px;overflow:auto;min-height: 100px;float:left;margin-left: 10px" align="center">
                                                    <div style="margin-top: 10px">
                                                        <h6><b>预览</b></h6>
                                                    </div>
                                                    <p v-html="col.blogContent">{{col.blogContent}}</p>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination  @change="pageChange($event)" v-model="blogSearchForm.pageNo" :total-rows="total" :per-page="blogSearchForm.pageSize"></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Swal from "sweetalert2";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "文章列表",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                picBaseUrl: this.picServer,
                detailId:'',
                typeList:[],
                blogList: [],
                showDetail:false,
                title: "文章列表",
                total: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "age",
                sortDesc: false,
                blogSearchForm:{
                    pageNo:1,
                    pageSize:10,
                    blogTitle:'',
                    typeId:'',
                },
                blogForm:{
                    blogTitle: '',
                    blogContent: '',
                    titlePage: '',
                    blogType: '',
                    summary: '',
                    quoteUrl: '',
                    publishFlag: '',
                    typeId:'',
                    tagIds: [],
                }
            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.blogList.length;
            }
        },

        methods: {
            pageChange(e){
                this.blogSearchForm.pageNo = e ;
                this.getBlogList();
                console.log(e)
            },
            openDetail(id) {
                this.detailId = this.detailId == id ? '' : id;
            },
            // 编辑博客
            editBlog(blog) {
                this.$router.push({
                    path: "/admin/edit_blog",
                    query: {blog: JSON.stringify(blog)}
                })
            },
            clearCondition(){
                this.blogSearchForm = {
                    blogTitle:'',
                    typeId:'',
                    pageNo: 1,
                    pageSize: 10,
                }
                this.getBlogList();

            },
            searchData(){
                this.blogSearchForm.pageNo = 1;
                this.blogSearchForm.pageSize = 10;
                this.getBlogList();
            },
            async getBlogList(){
                const {data: res} = await this.$blog.post('/blog/getall',this.blogSearchForm)
                this.blogList = res.data.records;
                this.total = res.data.total
            },
            async getTypeList(){
                // 得到所有的分类
                const {data: res} = await this.$blog.get('/type/getall')
                this.typeList = res.data
            },
            // 置顶
            async isTop (col,isTop){
                const {data: res} = await this.$blog.post('/blog/isTop/'+col.id+'&'+ isTop)
                if (res.code == '200') {
                    this.getBlogList();
                    return true;
                }else{
                    return  false;
                }

            },
            removeBlog(col) {
                console.log(col)
                Swal.fire({
                    text: "此操作将永久删除该博客, 是否继续!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(result => {
                    if (result.value) {
                        if (this.deleteBlog(col.id)) {
                            Swal.fire("Deleted!", "Your file has been deleted.", "success");
                        }
                    }
                });
            },
            async deleteBlog (id){
                const {data: res} = await this.$blog.post('/blog/delete/'+id)
                if (res.code == '200') {
                    this.getBlogList();
                    return true;
                }else{
                    return  false;
                }

            },

        },
        created() {
            this.getBlogList();
            this.getTypeList();
        },
    };
</script>

<style>
    i {
        cursor: pointer;
    }
    .form-control-sm {
        height: calc(1.5em + 0.5rem + 12px);
    }
    a :hover{
        color:#8fd0cc
    }
</style>